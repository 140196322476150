<template>
  <div style="width:100%">
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" md="2">
         <v-select
          v-model="selectTipoFiltro"
          :hint="`${selectTipoFiltro.state}`"
          :items="filtros"
          @change="getTipificaciones"
          item-text="state"
          item-value="abbr"
          persistent-hint
          return-object
          single-line
        ></v-select>
        </v-col>
        <v-col cols="12" md="2" v-if="this.selectTipoFiltro.state === 'Codigo'">
         <v-autocomplete v-model="selectTipo" :items="codigoTipo" :loadingData="loadingData"  item-text="descripcion" item-value="tipo" :color="colores.primario" label="BANDEJA/HISTORICO *"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" v-if="this.selectTipoFiltro.state === 'No. Documento'">
         <v-autocomplete v-model="selectTipo" :items="codigoTipo" :loadingData="loadingData"  item-text="descripcion" item-value="tipo" :color="colores.primario" label="BANDEJA/HISTORICO *"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" v-if="this.selectTipoFiltro.state === 'Area'">
         <v-autocomplete v-model="selectArea" :items="areaUser" :loadingData="loadingData" @change="getUsersArea" item-text="descripcion" item-value="id" :color="colores.primario" label="Areas *"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" v-if="this.selectTipoFiltro.state === 'Calidad'">
         <v-autocomplete v-model="selectArea" :items="areaUser" :loadingData="loadingData" @change="getUsersArea" item-text="descripcion" item-value="id" :color="colores.primario" label="Areas *"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" v-if="this.selectTipoFiltro.state === 'Mi Calidad'">
         <v-autocomplete v-model="selectMonth" :items="month"  item-text="descripcion" item-value="id" :color="colores.primario" label="Mes *"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
         <v-text-field  v-if="this.selectTipoFiltro.state === 'Codigo' && selectTipo === 'Bandeja'" v-model="itemFilter.codigo" ></v-text-field>
         <v-text-field  v-if="this.selectTipoFiltro.state === 'Codigo' && selectTipo === 'Histórico'" v-model="itemFilter.codigo" ></v-text-field>
         <v-text-field  v-if="this.selectTipoFiltro.state === 'No. Documento' && selectTipo === 'Bandeja'" v-model="itemFilter.documento" ></v-text-field>
         <v-text-field  v-if="this.selectTipoFiltro.state === 'No. Documento' && selectTipo === 'Histórico'" v-model="itemFilter.documento" ></v-text-field>
         <v-autocomplete v-if="this.selectTipoFiltro.state === 'Producto'" v-model="itemFilter.producto" :items="itemFilter.productos" :loadingData="loadingData"  item-text="descripcion" item-value="id" :color="colores.primario" label="TIPO DE PRODUCTO *"></v-autocomplete>
         <v-autocomplete  v-if="this.selectTipoFiltro.state === 'Origen'" v-model="itemFilter.origen" :items="itemFilter.origenes"  item-text="descripcion" item-value="id" :color="colores.primario" label="TIPO DE ORIGEN *"></v-autocomplete>
         <v-autocomplete v-if="this.selectTipoFiltro.state === 'Estado'" v-model="itemFilter.estado" :items="itemFilter.estados"  item-text="descripcion" item-value="id" :color="colores.primario" label="TIPO DE ESTADO *"></v-autocomplete>
         <v-autocomplete v-if="this.selectTipoFiltro.state === 'Tipo Requerimiento'" v-model="itemFilter.tipo" :items="itemFilter.tipos"  item-text="descripcion" item-value="id" :color="colores.primario" label="TIPO DE REQUERIMIENTO *"></v-autocomplete>
         <v-autocomplete v-if="this.selectTipoFiltro.state === 'Area' && selectArea !== ''" v-model="selectUser" :items="users"  item-text="usuario.nombre_usuario" item-value="usuario.id_usuario" :color="colores.primario" label="USUARIOS *"></v-autocomplete>
         <v-autocomplete v-if="this.selectTipoFiltro.state === 'Calidad' && selectArea !== ''" v-model="selectUser" :items="users"  item-text="usuario.nombre_usuario" item-value="usuario.id_usuario" :color="colores.primario" label="USUARIOS *"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-col cols="12" md="2">
                <v-btn @click="buscar"  block><v-icon>mdi-magnify</v-icon></v-btn>
          </v-col>
        </v-col>
        <v-col cols="12" md="2">
          <v-row>
            <v-col cols="12" md="6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="refrescar" block v-bind="attrs" v-on="on"> <v-icon>mdi-refresh</v-icon> </v-btn>
                </template>
                <span>Refrescar</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="6" v-if="session.area === 16">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn block v-bind="attrs" v-on="on" @click="nuevo_pqr"><v-icon>mdi-file-outline</v-icon> </v-btn>
                </template>
                <span>Crear PQR</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <dialogoCrm :dialogo="dialogo" :session="session" :loadingData="loadingData" :items="items" :tokenInbound="tokenInbound" :colores="colores" @closed_venta="cerrar_venta"></dialogoCrm>
    </v-container>
  </div>
</template>

<script>
// import dialogoCrm from '@/components/crm/dialogoComponent.vue'
import recBandeja from '@/js/rec_bandeja.js'
import recFiltros from '@/js/rec_filtros.js'
import recResignar from '@/js/rec_reasignar.js'
import recCalidad from '@/js/rec_calidad.js'

export default {
  name: 'bandeja_pqrsComponent',
  components: {
    dialogoCrm: () => import('@/components/crm/dialogoComponent.vue')
  },
  mixins: [recBandeja, recFiltros, recResignar, recCalidad],
  props: ['colores', 'items', 'loadingData', 'session', 'bandeja_cerrada'],
  data: () => ({
    dialogo: false,
    tokenInbound: 0,
    itemFilter: { productos: [], producto: '', origenes: [], origen: '', estados: [], estado: '', tipos: [], tipo: '', codigo: '', documento: '' },
    selectTipoFiltro: { state: 'Codigo' },
    codigoTipo: [],
    selectTipo: '',
    mdActualizar: 2,
    filtros: [],
    areaUser: [{ id: 5, descripcion: 'ODT' }, { id: 14, descripcion: 'ANALISTAS' }, { id: 15, descripcion: 'FRAUDE' }, { id: 16, descripcion: 'RADICADORES' }],
    selectArea: '',
    users: [],
    selectUser: '',
    month: [{ id: 1, descripcion: 'Enero' }, { id: 2, descripcion: 'Febrero' }, { id: 3, descripcion: 'Marzo' }, { id: 4, descripcion: 'Abril' }, { id: 5, descripcion: 'Mayo' }, { id: 6, descripcion: 'Junio' }, { id: 7, descripcion: 'Julio' }, { id: 8, descripcion: 'Agosto' }, { id: 9, descripcion: 'Septiembre' }, { id: 10, descripcion: 'Octubre' }, { id: 11, descripcion: 'Noviembre' }, { id: 12, descripcion: 'Diciembre' }],
    selectMonth: ''

  }),
  created () {
    this.rec_subEstados(this.session.id)
    this.rec_aplicativo()
    this.rec_producto()
    if (this.session.area !== 16 && this.session.area !== 17) {
      this.filtros = [{ state: 'Codigo' }, { state: 'No. Documento' }, { state: 'Producto' }, { state: 'Origen' }, { state: 'Estado' }, { state: 'Tipo Requerimiento' }, { state: 'Vencidos' }]
      this.codigoTipo = ['Bandeja', 'Histórico']
    } else {
      this.filtros = [{ state: 'Codigo' }, { state: 'No. Documento' }, { state: 'Producto' }, { state: 'Origen' }, { state: 'Estado' }]
      this.codigoTipo = ['Bandeja']
    }
    if (this.session.area === 17) {
      this.filtros = [{ state: 'Area' }, { state: 'Codigo' }, { state: 'No. Documento' }, { state: 'Calidad' }]
      // Eliminado-> this.filtros = [{ state: 'Area' }, { state: 'Codigo' }, { state: 'No. Documento' }, { state: 'Producto' }, { state: 'Origen' }, { state: 'Estado' }]
      this.codigoTipo = ['Bandeja', 'Histórico']
    }
    if (this.bandeja_cerrada) {
      this.filtros = [{ state: 'Codigo' }, { state: 'No. Documento' }, { state: 'Producto' }, { state: 'Origen' }, { state: 'Tipo Requerimiento' }, { state: 'Mi Calidad' }]
      this.codigoTipo = ['Bandeja']
    }
    if (this.bandeja_cerrada && this.session.area === 17) {
      this.filtros = [{ state: 'Codigo' }, { state: 'Calidad' }, { state: 'No. Documento' }, { state: 'Producto' }, { state: 'Origen' }, { state: 'Tipo Requerimiento' }]
      this.codigoTipo = ['Bandeja']
    }
  },
  methods: {
    nuevo_pqr () {
      this.tokenInbound = this.tokenInbound + 1
      this.dialogo = true
    },
    cerrar_venta () {
      this.dialogo = false
    },
    refrescar () {
      this.$emit('iniciarConsulta')
      this.refrescarData(this.session.id, this.bandeja_cerrada)
      this.itemFilter.producto = ''
      this.itemFilter.codigo = ''
      this.itemFilter.origen = ''
      this.itemFilter.estado = ''
      this.itemFilter.documento = ''
      this.itemFilter.tipo = ''
      this.selectTipo = ''
      this.selectUser = ''
      this.selectMonth = ''
      this.selectArea = ''
    },
    getUsersArea () {
      this.rec_get_asesor(this.selectArea)
    },
    getTipificaciones () {
      // if (this.selectTipoFiltro.state === 'Producto') this.itemFilter.productos = this.items.productos
      this.itemFilter.producto = ''
      this.itemFilter.codigo = ''
      this.itemFilter.origen = ''
      this.itemFilter.estado = ''
      this.itemFilter.documento = ''
      this.itemFilter.tipo = ''
      this.selectTipo = ''
      this.selectUser = ''
      this.selectMonth = ''
      this.selectArea = ''

      if (this.selectTipoFiltro.state === 'Tipo Requerimiento') this.itemFilter.tipos = this.items.tiporequerimientos
    },
    buscar () {
      this.$emit('finalizarConsulta')
      if (this.selectTipoFiltro.state === 'Codigo' && this.selectTipo === 'Bandeja') this.filtroData(1, this.itemFilter.codigo, this.bandeja_cerrada)
      if (this.selectTipoFiltro.state === 'Codigo' && this.selectTipo === 'Histórico') this.rec_bandeja_qrs_id(this.itemFilter.codigo)
      if (this.selectTipoFiltro.state === 'No. Documento' && this.selectTipo === 'Bandeja') this.filtroData(6, this.itemFilter.documento, this.bandeja_cerrada)
      if (this.selectTipoFiltro.state === 'No. Documento' && this.selectTipo === 'Histórico') this.rec_bandeja_qrs_documento(this.itemFilter.documento)
      if (this.selectTipoFiltro.state === 'Producto') this.filtroData(2, (this.itemFilter.producto) ? this.itemFilter.producto : null, this.bandeja_cerrada)
      if (this.selectTipoFiltro.state === 'Origen') this.filtroData(3, this.itemFilter.origen, this.bandeja_cerrada)
      if (this.selectTipoFiltro.state === 'Estado') this.filtroData(4, this.itemFilter.estado, this.bandeja_cerrada)
      if (this.selectTipoFiltro.state === 'Tipo Requerimiento') this.filtroData(5, this.itemFilter.tipo, this.bandeja_cerrada)
      if (this.selectTipoFiltro.state === 'Vencidos') this.filtroData(7, this.itemFilter.documento, this.bandeja_cerrada)
      if (this.selectTipoFiltro.state === 'Mi Calidad' && this.selectMonth !== '') this.rec_bandeja_mis_calificaciones(this.session.id, this.selectMonth)
      if (this.selectTipoFiltro.state === 'Calidad' && this.selectArea !== '' && this.selectUser !== '') this.rec_bandeja_gestion_calidad(this.selectUser)
      if (this.selectTipoFiltro.state === 'Area' && this.selectArea !== '' && this.selectUser !== '') this.rec_bandeja_mis_pqrs(this.selectUser)
    }
  }
}
</script>
