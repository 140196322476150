import axios from 'axios'
export default {
  methods: {
    rec_subEstados ($id) {
      this.itemFilter.estados = []
      const urlroute = this.$store.getters.getUrl + 'subestados/' + $id
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        for (let index = 0; index < response.data.length; index++) {
          const element = response.data[index]
          if (!this.itemFilter.estados.includes(element.subestado) && element.subestado.descripcion !== 'CERRADO') {
            this.itemFilter.estados.push(element.subestado)
          }
        }
        for (let index = 0; index < this.itemFilter.estados.length; index++) {
          const element = this.itemFilter.estados[index]
          if (element.color === 'indigo\r\n') {
            element.descripcion = 'RECIBIDO CORREO'
            this.itemFilter.estados[index].descripcion = element.descripcion
          }
          if (element.color === 'pink') {
            element.descripcion = 'RECIBIDO SFC'
            this.itemFilter.estados[index].descripcion = element.descripcion
          }
          if (element.color === 'brown') {
            element.descripcion = 'RECIBIDO MICROSITIO'
            this.itemFilter.estados[index].descripcion = element.descripcion
          }
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingDepartamento = false
      })
    },
    rec_aplicativo () {
      this.itemFilter.origenes = []
      const urlroute = this.$store.getters.getUrl + 'aplicativos'
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.itemFilter.origenes = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingDepartamento = false
      })
    },
    rec_producto () {
      this.itemFilter.origenes = []
      const urlroute = this.$store.getters.getUrl + 'productos'
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.itemFilter.productos = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingDepartamento = false
      })
    }
  }
}
